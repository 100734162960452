/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import {environment} from "./environment/environment";

if (environment.production) {
  window.console.log = () => {}
  window.console.trace = () => {}
  window.console.warn = () => {}
  window.console.debug = () => {}
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
