import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(translate: TranslateService) {
    translate.addLangs(['en','fr']);
    translate.setDefaultLang('en');
    translate.use('fr');
    // this.ngxService.start(); // start spinner
  }
}
