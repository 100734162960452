import {KeycloakService} from "keycloak-angular";
import {environment} from 'src/environments/environment';

export function initializeKeycloak(keycloak: KeycloakService) {
  return async () => {
    try {
      await keycloak.init({
        config: {
          url: environment.Keycloak.url,
          realm: environment.Keycloak.realm,
          clientId: environment.Keycloak.clientId,
        },
        loadUserProfileAtStartUp: true,
        initOptions: {
          onLoad: 'check-sso',
          checkLoginIframe: false,
        },
      });
    } catch (e) {
      console.error('Erreur lors de l\'initialisation de Keycloak :', e);
    }
  };
}
