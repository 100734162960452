import {Component, OnInit} from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Router, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})

export class AdminLayoutComponent implements OnInit {
  routes!: object[];
  roles: string[] = [];
  showMenu= true;
  constructor(private keycloakService: KeycloakService,private router: Router) {
    this.roles = this.keycloakService.getUserRoles();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.showMenu = !router.url.includes('admin/clients-history');
      }
    });
  }

  hasAnyAuthority(authorities: string[] | string): boolean {
    return this.roles.some((authority: string) => authorities.includes(authority));
  }

  ngOnInit(): void {
    this.routes = [
      {
        label: 'Liste des Clients',
        routerLink: '/admin/clients',
        routerLinkActiveOptions: {exact: true},
      }
    ]
  }
}
