import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-profile-layout',
  templateUrl: './profile-layout.component.html',
  styleUrls: ['./profile-layout.component.scss']
})
export class ProfileLayoutComponent implements OnInit {
  routes!: object[];

  ngOnInit(): void {
    this.routes = [
      {
        label: 'Mes informations',
        routerLink: '/parametres/profil',
        routerLinkActiveOptions: {exact: true}
      },
      {
        label: 'Notifications',
        routerLink: '/parametres/profil/notifications',
      },
      {
        label: 'Templates email perso',
        routerLink: '/parametres/profil/models-email'
      },
    ]
  }

}
