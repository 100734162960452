import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-not-access-info',
  templateUrl: './not-access-info.component.html',
  styleUrls: ['./not-access-info.component.scss']
})
export class NotAccessInfoComponent {

  @Input() height!: number;

}
