import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent {

  constructor(private router: Router, private ngxService: NgxUiLoaderService) { 
  }

  isAuthPage(): boolean {
    return this.router.url.includes('auth');
  }
}
