<div class="" id="my-profile-page">
  <div class="container pt-0">
    <p-tabMenu [model]="routes" [scrollable]="true">
      <ng-template pTemplate="item" let-item>
        {{item.label}}
      </ng-template>
    </p-tabMenu>
    <router-outlet></router-outlet>
  </div>
</div>
