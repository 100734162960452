import { Constants } from '@app/core/constants/constants';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ApplicationConfigService {
  private endpointPrefix = Constants.API_ENDPOINT;

  setEndpointPrefix(endpointPrefix: string): void {
    this.endpointPrefix = endpointPrefix;
  }

  getEndpointFor(api: string): string {
    return new URL(api, this.endpointPrefix).toString();
  }
}
