import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-avatar-input',
  templateUrl: './avatar-input.component.html',
  styleUrls: ['./avatar-input.component.scss']
})
export class AvatarInputComponent {
  @Input() currentUrl!: string;
  @Input() form!: FormGroup;
  @Input() field!: string;
  @Input() required!: boolean;
  @Input() height!: number;
  @Input() label!: string;

  @Output() onFileChange: EventEmitter<File> = new EventEmitter<File>()

  constructor(private toastSvc: ToastrService) {

  }

  // Image Preview
  showPreview(file: File) {
    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.currentUrl = reader.result as string;
    }
    reader.readAsDataURL(file)
  }

  change(e: Event) {
    const target = e.target as HTMLInputElement;
    const files = target.files as FileList;
    const maxSizeInBytes: number = 1024 * 1024; // 1 MB
    const file = files[0];
    if (file.size > maxSizeInBytes) {
      this.toastSvc.error('La taille du fichier dépasse la limite autorisée. Limite 1MB', 'Erreur!!');
      return;
    }

    this.showPreview(file);
    this.form.get(this.field)?.setValue(file);
    this.onFileChange.emit(files[0])
  }

  protected readonly String = String;
}
