import { Component } from '@angular/core';

@Component({
  selector: 'app-request-failed',
  templateUrl: './request-failed.component.html',
  styleUrls: ['./request-failed.component.scss']
})
export class RequestFailedComponent {

}
