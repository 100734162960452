export enum AuthorityEnum {
    ADMIN_MATCHIT                    = "Admin_MatchIT",		               // Dediee aux administrateurs de Match IT
    ACTION_CANDIDATE_CONTACT_BASIS_R = 'ACTION_CANDIDATE_CONTACT_BASIS_R',		// L'utilisateur peut voir le titre et date de l'action Contact du candidat qui lui appartient  + Notification
    ACTION_CANDIDATE_CONTACT_C     = 'ACTION_CANDIDATE_CONTACT_C',  		// L'utilisateur peut créer une étape Contact sur le candidat qui lui appartient
    ACTION_CANDIDATE_CONTACT_D     = 'ACTION_CANDIDATE_CONTACT_D',  		// L'utilisateur peut supprimer une étape Contact qu'il a créé
    ACTION_CANDIDATE_CONTACT_DETAIL_R = 'ACTION_CANDIDATE_CONTACT_DETAIL_R',		// L'utilisateur peut voir le détail de l’étape Contact du candidat qui lui appartient
    ACTION_CANDIDATE_CONTACT_OTHER = 'ACTION_CANDIDATE_CONTACT_OTHER',		// Applique les mêmes droits que sélectionnés sur les candidats dont on est pas responsable
    ACTION_CANDIDATE_CONTACT_U     = 'ACTION_CANDIDATE_CONTACT_U',  		// L'utilisateur peut modifier une étape Contact qu'il a créé
    ACTION_CV_TEMPLATE_BASIS_R     = 'ACTION_CV_TEMPLATE_BASIS_R',  		// L'utilisateur peut voir le titre   et date de l’action Template CV du candidat qui lui appartient + Notification
    ACTION_CV_TEMPLATE_C           = 'ACTION_CV_TEMPLATE_C',        		// L'utilisateur peut créer une action Template CV sur le candidat qui lui appartient
    ACTION_CV_TEMPLATE_D           = 'ACTION_CV_TEMPLATE_D',        		// L'utilisateur peut supprimer une action Template CV qu'il a créé
    ACTION_CV_TEMPLATE_DETAIL_R    = 'ACTION_CV_TEMPLATE_DETAIL_R', 		// L'utilisateur peut voir le détail de l’action Template CV du candidat qui lui appartient
    ACTION_CV_TEMPLATE_OTHER       = 'ACTION_CV_TEMPLATE_OTHER',    		// Applique les mêmes droits que sélectionnés sur les candidats dont on est pas responsable
    ACTION_CV_TEMPLATE_U           = 'ACTION_CV_TEMPLATE_U',        		// L'utilisateur peut modifier une action Template CV qu'il a créé
    ACTION_REMINDER_BASIS_R        = 'ACTION_REMINDER_BASIS_R',     		// L'utilisateur peut voir le titre   et date de l’action Rappel sur candidat qui lui appartient + Notification
    ACTION_REMINDER_C              = 'ACTION_REMINDER_C',           		// L'utilisateur peut créer une action Rappel sur le candidat qui lui appartient
    ACTION_REMINDER_D              = 'ACTION_REMINDER_D',           		// L'utilisateur peut supprimer une action Rappel qu'il a créé
    ACTION_REMINDER_DETAIL_R       = 'ACTION_REMINDER_DETAIL_R',    		// L'utilisateur peut voir le détail de l’action Rappel du candidat qui lui appartient
    ACTION_REMINDER_OTHER          = 'ACTION_REMINDER_OTHER',       		// Applique les mêmes droits que sélectionnés sur les candidats dont on est pas responsable
    ACTION_REMINDER_U              = 'ACTION_REMINDER_U',           		// L'utilisateur peut modifier une action Rappel qu'il a créé
    ADMIN                          = 'ADMIN',                       		// Est un droit SuperAdmin implicant de pouvoir tout faire et attribuer des roles similaires aux autres. Uniquement lui peut ajouter un utilisateur à son rôle
    CANDIDATE_BASIS_R              = 'CANDIDATE_BASIS_R',           		// L'utilisateur peut voir les informations de base du candidat (sans contact)
    CANDIDATE_C                    = 'CANDIDATE_C',                 		// L'utilisateur peut créer un nouveau candidat
    CANDIDATE_CONTACT_R            = 'CANDIDATE_CONTACT_R',         		// L'utilisateur peut voir les informations de contact du candidat
    CANDIDATE_D                    = 'CANDIDATE_D',                 		// L'utilisateur peut supprimer des candidats
    CANDIDATE_DECISION_BASIS_R     = 'CANDIDATE_DECISION_BASIS_R',  		// L'utilisateur peut voir le titre et date de l’étape Décision du candidat qui lui appartient + Notification
    CANDIDATE_DECISION_C           = 'CANDIDATE_DECISION_C',        		// L'utilisateur peut créer une étape Décision sur le candidat qui lui appartient
    CANDIDATE_DECISION_D           = 'CANDIDATE_DECISION_D',        		// L'utilisateur peut supprimer une étape Décision qu'il a créé
    CANDIDATE_DECISION_DETAIL_R    = 'CANDIDATE_DECISION_DETAIL_R', 		// L'utilisateur peut voir le détail de l’étape Décision du candidat qui lui appartient
    CANDIDATE_DECISION_OTHER       = 'CANDIDATE_DECISION_OTHER',    		// Applique les mêmes droits que sélectionnés sur les candidats dont on est pas responsable
    CANDIDATE_DECISION_U           = 'CANDIDATE_DECISION_U',        		// L'utilisateur peut modifier une étape Décision qu'il a créé
    CANDIDATE_DOCUMENT_C           = 'CANDIDATE_DOCUMENT_C',        		// L'utilisateur peut ajouter les documents du candidat qui lui appartient
    CANDIDATE_DOCUMENT_D           = 'CANDIDATE_DOCUMENT_D',        		// L'utilisateur peut supprimer les documents du candidat qui lui appartient
    CANDIDATE_DOCUMENT_R           = 'CANDIDATE_DOCUMENT_R',        		// L'utilisateur peut voir les documents sur une fiche candidat
    CANDIDATE_INFO_U               = 'CANDIDATE_INFO_U',            		// L'utilisateur peux mettre a jour toutes les informations du candidat qui lui appartient
    CANDIDATE_ITW1_BASIS_R         = 'CANDIDATE_ITW1_BASIS_R',      		// L'utilisateur peut voir le titre  et date de l’étape Entretien 1 du candidat qui lui appartient  + Notification
    CANDIDATE_ITW1_C               = 'CANDIDATE_ITW1_C',            		// L'utilisateur peut créer une étape  Entretien 1 sur le candidat qui lui appartient
    CANDIDATE_ITW1_COMP_BASIS_R    = 'CANDIDATE_ITW1_COMP_BASIS_R', 		// L'utilisateur peut voir le titre et date de l’étape Entretien Complémentaire du candidat qui lui appartient   + Notification
    CANDIDATE_ITW1_COMP_C          = 'CANDIDATE_ITW1_COMP_C',       		// L'utilisateur peut créer une étape  Entretien Complémentaire sur le candidat qui lui appartient
    CANDIDATE_ITW1_COMP_D          = 'CANDIDATE_ITW1_COMP_D',       		// L'utilisateur peut supprimer une étape Entretien Complémentaire qu'il a créé
    CANDIDATE_ITW1_COMP_DETAIL_R   = 'CANDIDATE_ITW1_COMP_DETAIL_R',		// L'utilisateur peut voir le détail de l’étape Entretien Complémentaire du candidat qui lui appartient
    CANDIDATE_ITW1_COMP_OTHER      = 'CANDIDATE_ITW1_COMP_OTHER',   		// Applique les mêmes droits que sélectionnés sur les candidats dont on est pas responsable
    CANDIDATE_ITW1_COMP_U          = 'CANDIDATE_ITW1_COMP_U',       		// L'utilisateur peut modifier une étape Entretien Complémentaire qu'il a créé
    CANDIDATE_ITW1_D               = 'CANDIDATE_ITW1_D',            		// L'utilisateur peut supprimer une étape Entretien 1 qu'il a créé
    CANDIDATE_ITW1_DETAIL_R        = 'CANDIDATE_ITW1_DETAIL_R',     		// L'utilisateur peut voir le détail de l’étape Entretien 1 du candidat qui lui appartient
    CANDIDATE_ITW1_OTHER           = 'CANDIDATE_ITW1_OTHER',        		// Applique les mêmes droits que sélectionnés sur les candidats dont on est pas responsable
    CANDIDATE_ITW1_U               = 'CANDIDATE_ITW1_U',            		// L'utilisateur peut modifier une étape Entretien 1 qu'il a créé
    CANDIDATE_LIST_OTHER_R         = 'CANDIDATE_LIST_OTHER_R',      		// L'utilisateur peut voir la liste des candidats des autres membres de l’équipe
    CANDIDATE_LIST_R               = 'CANDIDATE_LIST_R',            		// L'utilisateur peut voir ses candidats (voir = peut entrer dans la fiche aussi)
    CANDIDATE_PROPOSAL_BASIS_R     = 'CANDIDATE_PROPOSAL_BASIS_R',  		// L'utilisateur peut voir le titre  et date de l’étape Proposition du candidat qui lui appartient + Notification
    CANDIDATE_PROPOSAL_C           = 'CANDIDATE_PROPOSAL_C',  		      // L'utilisateur peut créer une étape Proposition sur le candidat qui lui appartient
    CANDIDATE_PROPOSAL_D           = 'CANDIDATE_PROPOSAL_D',        		// L'utilisateur peut supprimer une étape Proposition qu'il a créé
    CANDIDATE_PROPOSAL_DETAIL_R    = 'CANDIDATE_PROPOSAL_DETAIL_R', 		// L'utilisateur peut voir le détail de l’étape Proposition du candidat qui lui appartient
    CANDIDATE_PROPOSAL_OTHER       = 'CANDIDATE_PROPOSAL_OTHER',    		// Applique les mêmes droits que sélectionnés sur les candidats dont on est pas responsable
    CANDIDATE_PROPOSAL_U           = 'CANDIDATE_PROPOSAL_U',        		// L'utilisateur peut modifier une étape Proposition qu'il a créé
    COMPANY_BILLING_R              = 'COMPANY_BILLING_R',           		// L'utilisateur peut visualiser les informations de facturation de l’entreprise
    COMPANY_CV_TEMPLATE_C          = 'COMPANY_CV_TEMPLATE_C',       		// L'utilisateur peut ajouter des templates CV
    COMPANY_CV_TEMPLATE_D          = 'COMPANY_CV_TEMPLATE_D',       		// L'utilisateur peut supprimer des templates CV
    COMPANY_KBIS_C                 = 'COMPANY_KBIS_C',              		// L'utilisateur peut ajouter un KBIS + Notification Kbis ligne 113
    COMPANY_LOGO_C                 = 'COMPANY_LOGO_C',              		// L'utilisateur peut ajouter le logo de l’entreprise
    COMPANY_LOGO_U                 = 'COMPANY_LOGO_U',              		// L'utilisateur peut modifier le logo de l’entreprise
    COMPANY_P_KBIS_R               = 'COMPANY_P_KBIS_R',            		// L'utilisateur peut visualiser le précédent KBIS
    DOCUMENT_OTHER                 = 'DOCUMENT_OTHER',              		// Documents - Droits similaires sur les candidats dont l'utilisateur n'est pas responsable
    MEMBER_AUTHORITY_U             = 'MEMBER_AUTHORITY_U',          		// L'utilisateur peut modifier les droits d’un membre de l’équipe existant
    MEMBER_D                       = 'MEMBER_D',                    		// L'utilisateur peut supprimer un membre de l’équipe
    MEMBER_INVIT                   = 'MEMBER_INVIT',                		// L'utilisateur peut inviter un nouveau membre d’équipe
    MEMBER_LIST                    = 'MEMBER_LIST',                 		// L'utilisateur peut voir la liste des membres d’équipe
    PACKAGE_BILLING_R              = 'PACKAGE_BILLING_R',           		// L'utilisateur peut voir et donc consulter/ télécharger les factures.
    PACKAGE_BILLING_U              = 'PACKAGE_BILLING_U',           		// L'utilisateur peut modifier les informations de facturation
    PACKAGE_C                      = 'PACKAGE_C',                   		// L'utilisateur peut créer un plan MatchIT n + Notifications abonnement ligne 114 et 115
    PACKAGE_STOP                   = 'PACKAGE_STOP',                		// L’utilisateur peut arrêter le plan MatchIT n + Notifications abonnement ligne 114 et 115
    PACKAGE_U                      = 'PACKAGE_U',                   		// L'utilisateur peut modifier le plan MatchIT n + Notifications abonnement ligne 114 et 115
    PAGE_ACTU                      = 'PAGE_ACTU',                   		// L'utilisateur peut voir le menu et la page Actualités
    PAGE_COMPANY                   = 'PAGE_COMPANY',                		// L'utilisateur peut voir le menu et la page (Paramètres) Mon Entreprise
    PAGE_COMPANY_CV_TEMPLATE       = 'PAGE_COMPANY_CV_TEMPLATE',    		// L'utilisateur peut voir le menu et la page (Paramètres) Template CV
    PAGE_DASHBOARD                 = 'PAGE_DASHBOARD',              		// L'utilisateur peut voir le menu et la page Dashboard
    PAGE_EMAIL_TEMPLATE            = 'PAGE_EMAIL_TEMPLATE',         		// L'utilisateur peut voir le menu et la page (Paramètres) (Mon Profil) Templates Emails Perso
    PAGE_PACKAGE                   = 'PAGE_PACKAGE',                		// L'utilisateur peut voir le menu et la page (Paramètres) Abonnements/Factures
    PAGE_ROLE                      = 'PAGE_ROLE',                   		// L'utilisateur peut voir le menu et la page (Paramètres) Droits et Users
    PROFILE_AUTHORITY_LIST         = 'PROFILE_AUTHORITY_LIST',      		// L'utilisateur peut visualiser le/les rôle(s) qui lui sont attribués et leur description.
    PROFILE_D                      = 'PROFILE_D',                   		// L'utilisateur peut supprimer son compte
    PROFILE_EMAIL_TEMPLATE_C       = 'PROFILE_EMAIL_TEMPLATE_C',    		// L’utilisateur peut ajouter des templates emails
    PROFILE_EMAIL_TEMPLATE_D       = 'PROFILE_EMAIL_TEMPLATE_D',    		// L’utilisateur peut supprimer des templates emails
    PROFILE_EMAIL_TEMPLATE_R       = 'PROFILE_EMAIL_TEMPLATE_R',    		// L'utilisateur peut voir la liste des template emails
    PROFILE_EMAIL_TEMPLATE_U       = 'PROFILE_EMAIL_TEMPLATE_U',    		// L’utilisateur peut modifier des templates emails
    PROFILE_U                      = 'PROFILE_U',                   		// L'utilisateur peut modifier ses informations personnelles
    RECRUITER                      = 'RECRUITER',                   		// Permet d'activer son profil recruteur, L'utilisateur peut voir le menu et la page Recrutement
    ROLE_ASSIGN                    = 'ROLE_ASSIGN',                 		// Peut assigner des rôles aux membres de son équipe
    ROLE_C                         = 'ROLE_C',                      		// L'utilisateur peut créer un nouveau role
    ROLE_D                         = 'ROLE_D',                      		// L'utilisateur peut supprimer un role
    ROLE_U                         = 'ROLE_U',                      		// L'utilisateur peut modifier un role
    TEAMMATE                       = 'TEAMMATE',                    		// Peut voir les autres membres du compte client (recruteurs, sourceurs, compta, etc.)
    TEAMMATE_FILTER_R              = 'TEAMMATE_FILTER_R',           		// L’utilisateur peut voir la liste des membres de l’équipe dans le filtre
}
