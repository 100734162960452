import {CommonModule, NgOptimizedImage} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ButtonModule} from "primeng/button";
import { HasAnyAuthorityDirective } from '../core/generic-components/auth/has-any-authority.directive';
import { HasNotAuthorityDirective } from '../core/generic-components/auth/has-not-authority.directive';
import { AvatarInputComponent } from '../core/generic-components/avatar-input/avatar-input.component';
import { LoaderComponent } from '../core/generic-components/loader/loader.component';
import { NotAccessInfoComponent } from '../core/generic-components/not-access-info/not-access-info.component';
import { RequestFailedComponent } from '../core/generic-components/request-failed/request-failed.component';
import {ImageModule} from "primeng/image";


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgOptimizedImage,
        ButtonModule,
        ImageModule
    ],
  declarations: [
    AvatarInputComponent,
    LoaderComponent,
    RequestFailedComponent,
    NotAccessInfoComponent,
    HasAnyAuthorityDirective,
    HasNotAuthorityDirective
  ],
  exports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    AvatarInputComponent,
    LoaderComponent,
    RequestFailedComponent,
    NotAccessInfoComponent,
    HasAnyAuthorityDirective,
    HasNotAuthorityDirective
  ],
})
export class SharedModule {
}
