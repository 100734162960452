<div *ngIf="!isConnected"
     class="surface-overlay px-2 lg:px-6 flex w-full top-0 justify-content-between border-bottom-1 surface-border z-5 fixed"
     style="min-height: 70px;">
  <a routerLink="/" class="flex mr-0 lg:mr-6 align-self-center">
    <img priority ngSrc="../../../assets/images/logo/logo-dark.png" alt="Image" height="45" width="120">
  </a>

  <ul class="list-none p-0 m-0 flex select-none w-full justify-content-end flex-row surface-border">
    <li>
      <div class="flex h-full align-items-center flex-row gap-4">
        <a pButton pRipple class="p-button-secondary p-button-sm p-button-rounded" (click)="register()">Inscription</a>
        <a pButton pRipple class="p-button-secondary p-button-sm p-button-outlined p-button-rounded" (click)="login()">Connexion</a>
      </div>
    </li>
  </ul>
</div>
<div *ngIf="isConnected"
     class="surface-overlay px-2 lg:px-6 flex w-full top-0 justify-content-between border-bottom-1 surface-border z-5 fixed"
     style="min-height: 70px;">
  <a routerLink="/" class="flex mr-0 lg:mr-6 align-self-center">
    <img priority ngSrc="../../../assets/images/logo/logo-dark.png" alt="Image" height="45" width="120">
  </a>

  <a pRipple pStyleClass="@next" enterClass="hidden" leaveToClass="hidden"
     class="p-element p-ripple cursor-pointer block lg:hidden text-700 align-self-center">
    <i class="pi pi-bars text-4xl"></i>
  </a>
  <div
    class="flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full surface-overlay left-0 top-100 z-1 shadow-2 lg:shadow-none">
    <ul class="list-none p-0 m-0 flex select-none flex-column lg:flex-row">
      <li>
        <a pRipple routerLinkActive="border-primary text-primary" routerLink="/"
           [routerLinkActiveOptions]="{exact: true}"
           class="flex h-full px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-primary border-left-2 lg:border-bottom-3 lg:border-left-none border-transparent hover:border-primary font-medium cursor-pointer transition-colors transition-duration-150">
          <span>{{ 'Navbar.Home' | translate }}</span>
        </a>
      </li>
      <li *jhiHasAnyAuthority="[authorityEnum.PAGE_DASHBOARD]" class="lg:relative">
        <a pRipple routerLinkActive="border-primary text-primary" routerLink="/dashboard"
           class="flex h-full px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-primary border-left-2 lg:border-bottom-3 lg:border-left-none border-transparent hover:border-primary font-medium cursor-pointer transition-colors transition-duration-150">
          <span>{{ 'Navbar.Dashboard' | translate }}</span>
        </a>
      </li>
      <li>
        <a pRipple routerLinkActive="border-primary text-primary" routerLink="/parametres"
           class="flex h-full px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-primary border-left-2 lg:border-bottom-3 lg:border-left-none border-transparent hover:border-primary font-medium cursor-pointer transition-colors transition-duration-150">
          <span>{{ 'Navbar.Example' | translate }}</span>
        </a>
      </li>
      <li *jhiHasAnyAuthority="[authorityEnum.ADMIN_MATCHIT]">
        <a pRipple routerLinkActive="border-primary text-primary" routerLink="/admin"
           class="flex h-full px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-primary border-left-2 lg:border-bottom-3 lg:border-left-none border-transparent hover:border-primary font-medium cursor-pointer transition-colors transition-duration-150">
          <span>{{ 'Navbar.Admin' | translate }}</span>
        </a>
      </li>
      <li *jhiHasAnyAuthority="[authorityEnum.RECRUITER]">
        <a pRipple routerLinkActive="border-primary text-primary" routerLink="/consultants"
           class="flex h-full px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-primary border-left-2 lg:border-bottom-3 lg:border-left-none border-transparent hover:border-primary font-medium cursor-pointer transition-colors transition-duration-150">
          <span>{{ 'Navbar.Candidats' | translate }}</span>
        </a>
      </li>
      <li *jhiHasAnyAuthority="[authorityEnum.RECRUITER]">
        <a pRipple routerLinkActive="border-primary text-primary" routerLink="/jobs"
           class="flex h-full px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-primary border-left-2 lg:border-bottom-3 lg:border-left-none border-transparent hover:border-primary font-medium cursor-pointer transition-colors transition-duration-150">
          <span>{{ 'Navbar.Jobs' | translate }}</span>
        </a>
      </li>
    </ul>
    <ul
      class="list-none p-0 m-0 flex select-none flex-column lg:flex-row border-top-1 surface-border lg:border-top-none">
      <!--<li>
        <a pRipple
           class="flex h-full px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-primary font-medium cursor-pointer transition-colors transition-duration-150">
          <i class="pi pi-inbox text-base lg:text-2xl mr-2 lg:mr-0"></i>
          <span class="block lg:hidden font-medium">Inbox</span>
        </a>
      </li>-->
      <li>
        <div class="lg:col-10 col-12" *ngIf="currentPlan">
          <ng-container *ngIf="currentPlan.planPackage.type == 'FREE' || currentPlan.planPackage.type == 'CUSTOM'; else notTimeBoxedPlan">
            <p class="mb-0">Version gratuite : {{ computeRemainingDays() }} jours restants</p>
          </ng-container>
          <ng-template #notTimeBoxedPlan>
            <p class="mb-0">{{ currentPlan.planPackage.label }}</p>
          </ng-template>
        </div>
      </li>
      <li>
        <a pRipple routerLink="/notifications" routerLinkActive="border-primary text-primary"
           class="flex h-full px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-primary border-left-2 lg:border-bottom-3 lg:border-left-none border-transparent hover:border-primary font-medium cursor-pointer transition-colors transition-duration-150">
          <i *ngIf="notificationCount === 0; else withBadge" class="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0"></i>
          <ng-template #withBadge>
            <i pBadge [value]="notificationCount+''" severity="danger"
               class="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0"></i>
          </ng-template>
          <span class="block lg:hidden font-medium">Notifications</span>
        </a>
      </li>
      <li class="lg:relative border-top-1 surface-border lg:border-top-none">
        <span pRipple
              pStyleClass="@next"
              enterClass="hidden"
              enterActiveClass="scalein"
              [hideOnOutsideClick]="true"
              leaveToClass="hidden"
              leaveActiveClass="fadeout"
              [ngClass]="{'border-primary': window.location.href.includes('/account')}"
              class="flex h-full px-6 p-3 lg:px-3 lg:py-2 align-items-center border-left-2 lg:border-bottom-3 lg:border-left-none border-transparent hover:border-primary font-medium cursor-pointer transition-colors transition-duration-150">
          <p-avatar
            [image]="currentUrl"
            shape="circle"
            class="mr-3 lg:mr-0"
            size="large"
          />
          <div class="block lg:hidden" *ngIf="user">
            <div class="text-900 font-medium">{{ user.firstName }} {{ user.lastName }}</div>
            <span class="text-600 font-medium text-sm">{{ user.email }}</span>
          </div>
        </span>
        <ul
          class="list-none py-0 px-6 m-0 lg:px-0 border-round shadow-0 lg:shadow-2 lg:border-1 border-50 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer right-0">
          <li>
            <a pRipple routerLink="/account" routerLinkActive="border-primary text-primary"
               style="margin-left: -1px; border-top-left-radius: 6px"
               class="p-ripple p-element flex p-3 align-items-center text-600 hover:text-primary border-left-3 border-transparent hover:border-primary transition-colors transition-duration-150">
              <i class="pi pi-user mr-3"></i>
              <span class="font-medium">Mon compte</span>
            </a>
          </li>
          <li>
            <a pRipple
               (click)="logout()"
               style="margin-left: -1px; border-bottom-left-radius: 6px"
               class="p-ripple p-element flex p-3 align-items-center text-600 hover:text-primary border-left-3 border-transparent hover:border-primary transition-colors transition-duration-150">
              <i class="pi pi-sign-out mr-3"></i>
              <span class="font-medium">Se déconnecter</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
