import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription, switchMap, tap } from 'rxjs';
import { BaseService } from '../base.service';
import { NotificationGroup } from '../../models/notification.models';

@Injectable({
  providedIn: 'root',
})
export class NotificationService extends BaseService {
  private numNotificationSubject: Subject<number> = new Subject<number>();

  constructor() {
    super('sourcing/notifications');
  }

  getNotificationPage(
    page: number,
    size: number
  ): Observable<NotificationGroup[]> {
    return this.http.get<NotificationGroup[]>(
      `${this.resourceUrl}?page=${page}&size=${size}`
    );
  }

  getNotReadNumber(): Observable<number> {
    return this.http.get<number>(`${this.resourceUrl}/non-read-number`).pipe(
      tap((num) => this.numNotificationSubject.next(num))
    );
  }

  updateReadNotification(): Subscription {
    return this.http
      .put<void>(`${this.resourceUrl}/read`, {})
      .pipe(switchMap(() => this.getNotReadNumber()))
      .subscribe();
  }

  setNumNotification(): Observable<number> {
    return this.http.get<number>(`${this.resourceUrl}/non-read-number`).pipe(
      tap((num) => this.numNotificationSubject.next(num))
    );
  }
}
