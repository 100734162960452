import { Component } from '@angular/core';

@Component({
  selector: 'app-iframe-layout',
  templateUrl: './iframe-layout.component.html',
  styleUrls: ['./iframe-layout.component.scss']
})
export class IframeLayoutComponent {

}
