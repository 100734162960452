import { Directive, Input, TemplateRef, ViewContainerRef, OnDestroy } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * @whatItDoes Conditionally includes an HTML element if current user has any
 * of the authorities passed as the `expression`.
 *
 * @howToUse
 * ```
 *     <some-element *jhiHasAnyAuthority="'ROLE_ADMIN'">...</some-element>
 *
 *     <some-element *jhiHasAnyAuthority="['ROLE_ADMIN', 'ROLE_USER']">...</some-element>
 * ```
 */
@Directive({
  selector: '[jhiHasAnyAuthority]',
})
export class HasAnyAuthorityDirective implements OnDestroy {
  private authorities!: string | string[];

  private readonly destroy$ = new Subject<void>();

  constructor(private keycloakService: KeycloakService, private templateRef: TemplateRef<any>, private viewContainerRef: ViewContainerRef) {}

  @Input()
  set jhiHasAnyAuthority(value: string | string[]) {
    this.authorities = value;
    this.updateView();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  hasAnyAuthority(authorities: string[] | string): boolean {
    var roles = this.keycloakService.getUserRoles();

    if (!Array.isArray(authorities)) {
      authorities = [authorities];
    }

    return roles.some((authority: string) => authorities.includes(authority));
  }

  private updateView(): void {
    const hasAnyAuthority = this.hasAnyAuthority(this.authorities);
    this.viewContainerRef.clear();
    if (hasAnyAuthority) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
