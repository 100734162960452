import {
  faArrowLeft,
  faArrowRight,
  faAsterisk,
  faBackwardStep,
  faBan,
  faBars,
  faBell,
  faBook,
  faBuilding,
  faCalendar,
  faCalendarAlt,
  faCalendarTimes,
  faCalendarXmark,
  faCheck,
  faCircleInfo,
  faClockRotateLeft,
  faCloud,
  faCloudDownload,
  faCogs,
  faDatabase,
  faDownload,
  faEuroSign,
  faExclamationCircle,
  faEye,
  faFileDownload,
  faFilePdf,
  faFlag,
  faFolderTree,
  faHeart,
  faHome,
  faHourglassStart,
  faInfo,
  faInfoCircle,
  faList,
  faLocation,
  faLocationPin,
  faLocationPinLock,
  faLock,
  faPencilAlt,
  faPlus,
  faQuestion,
  faRoad,
  faSave,
  faSearch,
  faSignOutAlt,
  faSignInAlt,
  faSort,
  faSortDown,
  faSortUp,
  faSync,
  faTachometerAlt,
  faTasks,
  faThList,
  faTimes,
  faTrash,
  faTrashAlt,
  faUser,
  faUsersBetweenLines,
  faUserPlus,
  faUsers,
  faUsersCog,
  faVolumeTimes,
  faWarning,
  faWrench,
  // jhipster-needle-add-icon-import
} from '@fortawesome/free-solid-svg-icons';

export const fontAwesomeIcons = [
  faArrowLeft,
  faArrowRight,
  faAsterisk,
  faBackwardStep,
  faBan,
  faBars,
  faBell,
  faBook,
  faBuilding,
  faCalendar,
  faCalendarAlt,
  faCalendarTimes,
  faCalendarXmark,
  faCheck,
  faCircleInfo,
  faClockRotateLeft,
  faCloud,
  faCloudDownload,
  faCogs,
  faDatabase,
  faDownload,
  faEuroSign,
  faExclamationCircle,
  faEye,
  faFileDownload,
  faFilePdf,
  faFlag,
  faFolderTree,
  faHeart,
  faHome,
  faHourglassStart,
  faInfo,
  faInfoCircle,
  faList,
  faLocation,
  faLocationPin,
  faLocationPinLock,
  faLock,
  faPencilAlt,
  faPlus,
  faQuestion,
  faRoad,
  faSave,
  faSearch,
  faSignOutAlt,
  faSignInAlt,
  faSort,
  faSortDown,
  faSortUp,
  faSync,
  faTachometerAlt,
  faTasks,
  faThList,
  faTimes,
  faTrash,
  faTrashAlt,
  faUser,
  faUsersBetweenLines,
  faUserPlus,
  faUsers,
  faUsersCog,
  faVolumeTimes,
  faWarning,
  faWrench,
  // jhipster-needle-add-icon-import
];
