<div class="flex align-items-end gap-3" [formGroup]="form">
  <p-image
    [src]="currentUrl"
    alt="Avatar"
    [height]="String(height)"
    [preview]="true"
  />
  <label pButton for="file" class="p-button-sm mb-1"
    title="format accepter: image/jpeg, image/png, image/gif; taille max 1MB">{{label}}</label>
  <input type="file" accept="image/jpeg, image/png, image/gif" [required]="required" class="hidden" id="file"
    (change)="change($event)" />
</div>
